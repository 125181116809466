<template>
  <div class="uinfo">
    <p class="tit">
      欢迎，<span class="blue">{{uname}}</span> 光临！
    </p>    
    <div v-if="userStatus === '1'">
      <p>
      您当前用户为 <span class="green">已审批</span> 状态。
     </p>
    </div>
    <div v-else>
      <p v-if="userStatus === '0'">
      您当前用户为 <span class="red">待审批</span> 状态，请完善您的 <router-link to="/member/useredit">用户信息</router-link>。
     </p>
     <p v-if="userStatus === '2'">
      您当前用户状态为审核未通过，请重新完善您的 <router-link to="/member/useredit">用户信息</router-link>。
     </p>
     <p v-if="userAudit !== null && userAudit !== ''">
      审核意见：{{userAudit}}
     </p>
     <p >
      只有注册为真实有效的企业用户，才能通过网站审核，并享有网站提供的服务。
     </p>
    </div>
    <div v-if="userStatus === '1'">
      <el-descriptions class="margin-top" title="用户基本信息" size="large" border >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><user /></el-icon>
              用户名
            </div>
          </template>
          {{userData.username}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><Avatar /></el-icon>
              联系人
            </div>
          </template>
          {{userData.linkman}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon v-if="userData.sex === '1'"><Female /></el-icon>
              <el-icon v-else><Male /></el-icon>
              性别
            </div>
          </template>
          <span v-if="userData.sex === '1'">女士</span>
          <span v-else>先生</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><iphone /></el-icon>
              联系电话
            </div>
          </template>
          {{userData.phoneNumber}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><Message /></el-icon>
              电子邮件
            </div>
          </template>
          {{userData.email}}
        </el-descriptions-item>
      </el-descriptions>
        
      <el-descriptions class="margin-top" title="用户企业信息" size="large" border >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><House /></el-icon>
              企业名称
            </div>
          </template>
          {{userData.nickName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><Avatar /></el-icon>
              法定代表人
            </div>
          </template>
          {{userData.legalPerson}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><iphone /></el-icon>
              法人电话
            </div>
          </template>
          Suzhou
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><Tickets /></el-icon>
              信用代码
            </div>
          </template>
          {{userData.creditCode}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon><location /></el-icon>
              企业地址
            </div>
          </template>
          {{userData.address}}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      // 当前登录用户名
      uname: '',
      userData: null,
      //用户审批状态
      userStatus: '',
      //审核意见
      userAudit: ''
    }
  },
  mounted () {
    // document.title = '云南省大学科技园'
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui    
    if (ui !== null) {
      this.uname = ui.uname
    } else {
      this.$router.push({ path: '/login' })            
    }
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      const params = '?username=' + this.uname
      const { data: ret } = await this.$http.get(this.$api.userinfoByuname + params)     
      console.log('userinfoRet', ret)
      if (ret.code === 1) {
        this.userData = ret.data
        this.userStatus = ret.data.auditStatus
        this.userAudit = ret.data.auditId
      }
    }
  }
}
</script>

<style scoped>
.uinfo {  
  display: block;
  /* height: 100%; */
  /* border: 1px solid #f00; */
	/* display: flex;  
	justify-content:center;
  align-items: center; */  
  text-align: center;
}
.uinfo .tit{
  margin-top: 70px;
  font-size: 20px;
}
.uinfo p{
  display: block;
  font-size: 16px;
}
.el-descriptions {
  margin-bottom: 20px;
}
.cell-item {
  display: flex;
  align-items: center;
  color: #333;
}
.cell-item .el-icon {
  font-size: 18px;
  margin-right: 10px;
}
</style>